<template>
  <div id="app">
    <div class="site-content">
      <img class="logo" alt="The Ashford Road CLub" src="./assets/logo.png">
    </div>
    <HomePage msg="Welcome to The Ashford"/>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 40px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.logo {
  width: 150px;
  margin-left: 17px;
}
@media only screen and (max-width: 768px) {
  .logo {
    margin-left: 0;
  }
}
.site-content {
  position: relative;
  margin: 0 20px;
}
@media only screen and (max-width: 768px) {
  #app {
    text-align: center;
  }
}
</style>
