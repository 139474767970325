<template>
  <div class="event-list">
    <div class="site-content">
      <h3 class="events-title">Upcoming Events</h3>
    </div>

    <div class="flex-container">

        <div v-for="(event) in orderedEvents" :key="event.name" class="event">
          
          <div class="event-padding">
            <div class="event-image-container">
              <a v-if="event.image" :href="require('@/assets/event_images/'+event.image)">
                <div :style="{'background-image': `url(${require('@/assets/event_images/'+event.image+'')})`}" class="image" :class="getBackgroundPositionClass(event.image_position)"></div>
              </a>  
              <a v-else :href="require('@/assets/event_images/event.jpg')">
                <div :style="{'background-image': `url(${require('@/assets/event_images/event.jpg')})`}" class="image"></div>
              </a>
            </div>

            <div class="cal-box">
              <div v-html="getMonth(new Date(event.start))" class="dow"></div>
              <div v-html="new Date(event.start).getDate()" class="dom"></div>
              <div v-html="getDow(new Date(event.start))" class="month"></div>
            </div>

            <h3 v-html="event.name" class="name"></h3>
            <div class="time">Starts <span v-html="getTime(new Date(event.start))"></span></div>
            <div v-if="event.description" v-html="event.description" class="description"></div>
            <div class="info-button-container">
              <a v-if="event.fb_link" target="_blank" :href="event.fb_link" class="facebook">More Info</a>
            </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import { events } from '../event_data/events.js';
export default {
  data() {
    return {
      events: events,
      options: {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        dayOrdinal: 'numeric'
      }
    }
  },
  methods: {
    formatDate: function(dateObj) {
      const formatter = new Intl.DateTimeFormat('en-GB', this.options);
      return formatter.format(dateObj);
    },
    getDow: function(dateObj) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return daysOfWeek[dateObj.getDay()];
    },
    getMonth: function(dateObj) {
      return dateObj.toLocaleString('default', { month: 'long' });
    },
    getTime: function(dateObj) {
      return dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    },
    getBackgroundPositionClass(data) {
      if(data === "top") {
        return "background-top"
      }
      if(data === "bottom") {
        return "background-bottom"
      }
      return "background-center"
    },
  },
  computed: {
    orderedEvents() {
      let eventsClone =  JSON.parse(JSON.stringify(this.events))
      const now = new Date();
      let newEvents =  eventsClone
        .filter(event => new Date(event.start) > now)
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      return newEvents
    },
  },
}
</script>

<style scoped>
.events-title {
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.events {
  margin-left: auto;
  margin-right: auto;
  margin-block-start: 1em;
    margin-block-end: 1em;
}

.events tr td {
  text-align: right;
}
.events tr td:first-child {
  text-align: left;
}
.eventOLD {
  position: relative;
  display: block;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  text-align: left;
}
.event {
  width: 33.33%; /* 3 columns */
  box-sizing: border-box;
  padding: 10px;
  padding-top: 33.33%;
  color: #ffffff;
}

.event .cal-box {
  margin: 16px;
  float: left;
  width: 56px;
  text-align: center;
  
}

.event .cal-box .month {
  font-size: 11px;
  text-transform: uppercase;
}

.event .cal-box .dom {
  font-size: 36px;
  line-height: 38px;
  font-weight: 900;
}

.event .cal-box .dow {
  font-size: 11px;
  text-transform: uppercase;
}

.event .event-padding {
  margin: 10px;
  margin-top: 0;
  background-color: #333333;
}

.event .event-image-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100%;
}

@media only screen and (max-width: 1200px) {
  .event {
    width: 50%; /* 2 columns */
    padding-top: 50%;
  }
  .event .event-image-container {
    margin-top: -100%;
  }
}

@media only screen and (max-width: 768px) {
  .event {
    width: 100%; /* 1 column */
    padding-top: 100%;
  }
  .event .event-image-container {
    margin-top: -103%;
  }
}
.event .image {
  height: 100%;
  width: 100%;
  background-size: cover;
}
.event .image.background-center {
  background-position: center;
}
.event .image.background-top {
  background-position: top;
}
.event .image.background-bottom {
  background-position: bottom;
}
.event .date {
  font-size: 12px;
  text-align: left;
  margin-top: 12px;
}
.event .time {
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 13px;
}
.event .name {
  text-align: left;
  font-size: 20px;
  padding: 8px 0;
  margin: 0;
  margin-top: 4px;
  margin-right: 16px;
}
.event .description {
  text-align: left;
  font-size: 14px;
  margin-right: 16px;
  margin-left: 88px;
  min-height: 40px;
}
.event .info-button-container {
  height: 60px;
}
.event .facebook {
  text-align: left;
  font-size: 14px;
  width: auto;
  padding: 8px;
  font-weight: bold;
  display: inline-block;
  background-color: #000;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
  margin: 8px 0;
  margin-top: 12px;
  float: right;
  margin-right: 16px;
}
</style>