import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

Vue.use(VueGtag, {
  config: { id: "G-R3MBPR4HMC" }
})
