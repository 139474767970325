<template>
  <div class="homepage">
    <div class="site-content">

      <div class="header">
        <div class="header-contact-details">
          <p>
            17-18 Ashford Rd, Swindon, SN1 3NT
          </p>
          <p><a href="tel:01793534643">01793 534643</a></p>
        </div>


        <div class="opening-times-container show-mobile">
          <h3>Opening Times</h3>

          <table class="opening-times">
            <tbody>
              <tr>
                <td>Monday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>12:00 - 23:45</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>12:00 - 23:45</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>12:00 - 23:00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <EventList></EventList>

      <div class="separator"></div>

      <div class="site-content features">

        <div class="feature-item">
          <h3>Amenities</h3>
          <ul>
            <li>Bar</li>
            <li>Air Conditioning</li>
            <li>Function Room</li>
            <li>Skittle Alley</li>
            <li>2 X Large Projectors</li>
            <li>4 X Flat Screens</li>
            <li>4 X Dart Boards</li>
            <li>Pool Table</li>
            <li>Jukebox</li>
            <li>Disabled Access</li>
          </ul>
        </div>

        <div class="feature-item show-desktop">
          <h3>Opening Times</h3>

          <table class="opening-times center-align">
            <tbody>
              <tr>
                <td>Monday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>18:00 - 23:00</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>12:00 - 23:45</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>12:00 - 23:45</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>12:00 - 23:00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="feature-item">
          <h3>Entertainment</h3>
          <ul>
            <li>Live Music</li>
            <li>Live Sport</li>
            <li>Bingo</li>
            <li>Quiz</li>
            <li>Skittles</li>
            <li>Cards</li>
            <li>Pool</li>
            <li>Darts</li>
            <li>Raffles</li>
            <li>Jokers Jackpot</li>
          </ul>
        </div>

        <div class="feature-item show-mobile">
          <h3>New Members Welcome!</h3>
        </div>
      </div>
        

      <div class="separator"></div>

      <div class="gallery">
        <div class="gallery-item">
          <a :href="require('@/assets/home/bar.jpg')">
            <img alt="Ashford Road CLub Bar" src="../assets/home/bar.jpg" />
          </a>
        </div>
        <div class="gallery-item">
          <a :href="require('@/assets/home/darts.jpg')">
            <img alt="Darts at The Ashford Road CLub" src="../assets/home/darts.jpg" />
          </a>
        </div>
        <div class="gallery-item">
          <a :href="require('@/assets/home/pool.png')">
            <img alt="Projector for live sports and pool" src="../assets/home/pool.png" />
          </a>
        </div>
        <div class="gallery-item">
          <a :href="require('@/assets/home/camra.jpeg')">
            <img alt="CAMRA Regional Club Of The Year" src="../assets/home/camra.jpeg" />
          </a>
        </div>
        <div class="gallery-item">
          <a :href="require('@/assets/home/cask1.png')">
            <img alt="Cask Marque Beer and cellar report" src="../assets/home/cask1.png" />
          </a>
        </div>
        <div class="gallery-item">
          <a :href="require('@/assets/home/cask2.png')">
            <img alt="Cask Marque summary" src="../assets/home/cask2.png" />
          </a>
        </div>
      </div>

      <div class="separator"></div>

      <div>
        <div class="privacy-policy" v-if="showPrivacyPolicy">
          <h2>Privacy Policy</h2>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Purpose of the processing and the lawful basis for the processing</p>
                </td>
                <td>
                  <p>Your information will be stored to enable the Ashford Road Club Limited to provide membership
                    services.
                  </p>
                  <p>You can choose to join our emailing list which will inform you of club activities.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The legitimate interests of the controller or third party, where applicable</p>
                </td>
                <td>
                  <p>N/A</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Any recipient or categories of recipients of the personal data</p>
                </td>
                <td>
                  <p>Your information will not be shared with third parties although we use MailChimp.com as data
                    processors
                    to provide services for you.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Details of transfers to third country and safeguards</p>
                </td>
                <td>
                  <p>MailChimp complies with GDPR regulations and other data processing rules.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Retention period or criteria used to determine the retention period</p>
                </td>
                <td>
                  <p>Information about members will be retained for a maximum of six years (in line with HMRC tax law)
                    after
                    the last contact with us.</p>
                  <p>Information about email list subscribers will be deleted after two years of inaction/no contact.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The existence of each of data subject's rights:</p>
                  <ul>
                    <li>The right to be informed</li>
                    <li>The right of access</li>
                    <li>The right to rectification</li>
                    <li>The right to erasure</li>
                    <li>The right to restrict processing</li>
                    <li>The right to data portability</li>
                    <li>The right to object</li>
                    <li>Rights in relation to automated decision making and profiling.</li>
                  </ul>
                </td>
                <td>
                  <p>Your rights under the GDPR are not affected and you have the right to view your data, correct any
                    error
                    or ask for it to be erased. You can ask for a copy of your records at any time.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The right to withdraw consent at any time, where relevant</p>
                </td>
                <td>
                  <p>You can cancel your membership or opt out of the emailing list at any time.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The existence of automated decision making, including profiling and information about how decisions
                    are
                    made, the significance and the consequences.</p>
                </td>
                <td>
                  <p>The Ashford Road Club Limited does not use any automated decision-making or profiling technologies.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Identity and contact details of the controller (and where applicable, the controller’s
                    representative)
                    and the data protection officer</p>
                </td>
                <td>
                  <p>Your information is managed by the secretary who can be contacted at ashfordroadclub@btconnect.com
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>The right to lodge a complaint with a supervisory authority</p>
                </td>
                <td>
                  <p>If you wish to complain about the handling of your personal data you can make a complaint to the
                    Information Commissioner’s Office (www.ico.org.uk)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Whether the provision of personal data part of a statutory or contractual requirement or obligation
                    and
                    possible consequences of failing to provide the personal data</p>
                </td>
                <td>
                  <p>If you choose not to provide personal information for us to process, the Ashford Road Club Limited
                    would
                    be unable to provide membership services for you or send you emails about upcoming events.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footer">
          <div class="privacy-policy-link"><a v-on:click="showPrivacyPolicy = !showPrivacyPolicy">Privacy Policy</a></div>
          <div>&copy; Copyright Ashford Road Club <span v-html="thisYear()"></span></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import EventList from "./EventList.vue"

export default {
    name: "HelloWorld",
    props: {
        msg: String
    },
    data() {
        return {
            showPrivacyPolicy: false
        };
    },
    components: { EventList },
    methods: {
      thisYear: function () {
        const now = new Date()
        return now.getFullYear()
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-contact-details {
    text-align: right;
    margin-top: -80px;
}
h1 {
  font-size: 1.5em;
}
h3 {
  margin: 40px 0 0;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 900;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
  margin: 0 10px;
}
a {
  color: #0074D9;
}
.opening-times {
  margin-block-start: 1em;
    margin-block-end: 1em;
}
.opening-times tr td {
  text-align: right;
}
.opening-times tr td:first-child {
  text-align: left;
}
.event-list {
  width: 100%;
}
.photos {
  padding: 0px 20px;
}
.photos img {
  margin: 0px auto;
  margin-block-start: 1em;
    margin-block-end: 1em;
  width: 100%;
  max-width: 400px;
  display: block;
}
.privacy-policy table tr td {
  vertical-align: top;
  padding: 10px;
  font-size: 9px;
}
.privacy-policy table tr td ul {
  list-style: disc;    
}
@media only screen and (max-width: 768px) {
  .header-contact-details {
    position: relative;
    text-align: center;
    margin-top: 0;
  }
  .opening-times {
    margin-left: auto;
    margin-right: auto;
  }
}

.features {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.feature-item {
  width: 33.33%; /* 3 columns */
  box-sizing: border-box;
  padding: 19px;
  text-align: center;
}
.feature-item ul {
  margin: 0;
  padding: 0;
  margin-top: 18px;
}
.feature-item ul li {
  margin: 0;
  padding: 0;
  margin-top: 4px;
}
.show-mobile {
  display: none;
}
.center-align {
  margin-left: auto;
  margin-right: auto;
}
.separator {
  border-top: 1px solid #666;
  margin-top: 41px;
  margin-right: 19px;
  margin-left: 19px;
}
.gallery {
  margin-top: 46px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
.gallery-item {
  width: 33.33%; /* 3 columns */
  box-sizing: border-box;
  padding: 10px;
  height: 380px;
  color: #ffffff;
}
.gallery-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.header{
  margin-left: 17px;
  margin-right: 17px;
}

.footer {
  margin-left: 17px;
  margin-right: 17px;
  padding-top: 22px;
  padding-bottom: 22px;
}
.privacy-policy-link {
  float: right
}

@media only screen and (max-width: 1200px) {
  .feature-item, .gallery-item {
    width: 50%; /* 2 columns */
  }
}

@media only screen and (max-width: 768px) {
  .feature-item, .gallery-item {
    width: 100%; /* 1 column */
  }
  .show-mobile {
    display: block;
  }
  .show-desktop {
    display: none;
  }
  .privacy-policy-link {
    float: none;
    margin-bottom: 10px;
  }
}
</style>
