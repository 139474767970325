export const events = [
  {
    start: "2023-04-16 20:00",
    name: "Quiz with Paul & George",
    description:
      "Quiz will start at 8pm. Free nibbles. Teams of up to 6 people",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "quiz.png",
    image_position: "center",
  },
  {
    start: "2023-04-22 20:30",
    name: "DJ Northern Soul & Motown",
    description: "A retrospective of Soul music and all its influences",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "all-things-soul.png",
    image_position: "center",
  },
  {
    start: "2023-04-29 21:00",
    name: "Darren Hunt",
    description:
      "A unique evening of fabulous guitar skills and an eclectic mix of music",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "darren_hunt.jpg",
    image_position: "top",
  },
  {
    start: "2023-04-30 19:00",
    name: "Over the Hill",
    description: "Playing a mix of classic tracks from the 50's through to now",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "over-the-hill.jpg",
    image_position: "top",
  },
  {
    start: "2023-05-06 10:30",
    name: "King Charles III Coronation",
    description: "Celebration of King Charles III on all screens",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coronation.png",
    image_position: "center",
  },
  {
    start: "2023-05-06 21:00",
    name: "Mean as Custard",
    description: "Pop-rock-punk-indie",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "mean-as-custard.jpg",
    image_position: "top",
  },
  {
    start: "2023-05-07 12:00",
    name: "King Charles III Coronation",
    description: "Continued coverage of the Kings celebrations",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coronation2.jpg",
    image_position: "center",
  },
  {
    start: "2023-05-07 13:00",
    name: "Sunday Bingo",
    description: "Followed by Tote, Americano, current jokers Jackpot",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "bingo.jpg",
    image_position: "center",
  },
  {
    start: "2023-05-07 16:00",
    name: "An Afternoon of Horse Racing",
    description: "Horses available for purchase from 15th April from the bar",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "horse-racing.png",
    image_position: "center",
  },
  {
    start: "2023-05-07 19:00",
    name: "Coronation Concert",
    description: "World class line-up of acts at Windsor Castle",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coronation-concert.jpg",
    image_position: "center",
  },
  {
    start: "2023-05-13 21:00",
    name: "No Middle Ground",
    description:
      "Human League, Sex Pistols, Supergrass, Primal Scream, T Rex, and more...",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "nmg.jpg",
    image_position: "top",
  },
  {
    start: "2023-05-20 20:30",
    name: "Sugar Motown - Sophia",
    description: "Motown Singer",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sugar.jpg",
    image_position: "top",
  },
  {
    start: "2023-05-27 20:30",
    name: "Drew Bryant",
    description: "Solo Guitarist & Singer",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "drew.png",
    image_position: "top",
  },
  {
    start: "2023-05-28 20:00",
    name: "Steven Tilley",
    description: "Back by Popular Demand!!",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "steven-tilley.jpg",
    image_position: "top",
  },
  {
    start: "2023-06-09 20:00",
    name: "Music Quiz",
    description: "Winning team takes all!",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "music_quiz.png",
    image_position: "top",
  },
  {
    start: "2023-06-18 16:00",
    name: "Tildy",
    description: "Tildy @ The Ashford",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "tildy.jpg",
    image_position: "top",
  },
  {
    start: "2023-06-10 21:00",
    name: "Billy and Louie",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "billy_and_louie.jpg",
    image_position: "top",
  },
  {
    start: "2023-06-24 20:00",
    name: "Sister Sister",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sistersister.jpg",
    image_position: "top",
  },
  {
    start: "2023-06-17 21:00",
    name: "Mitch Coleman",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "mitchcoleman.png",
    image_position: "top",
  },
  {
    start: "2023-06-30 20:00",
    name: "Copper Creek",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coppercreek.jpg",
    image_position: "top",
  },

  {
    start: "2023-09-30 20:00",
    name: "Nowhere Road",
    description: "Top UK Country Band",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "nowhere_road.jpg",
    image_position: "top",
  },
  {
    start: "2023-09-23 20:00",
    name: "Disco",
    description: "Super Star DJ Liam O'Neill",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "Night_Disco_Party_Poster.jpg",
    image_position: "middle",
  },
  {
    start: "2023-08-24 18:30",
    name: "Swindon Darts Tournament",
    description: "Summer Series Tour",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "Swindon_Darts.jpg",
    image_position: "top",
  },
  {
    start: "2023-09-02 20:00",
    name: "Mike E",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "Mike-E.jpg",
    image_position: "middle",
  },
  {
    start: "2023-08-26 20:30",
    name: "Locarno Beat",
    description: "The Sound of the Swinging 60s and 70s",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "locarno_beat_aug23.jpg",
    image_position: "top",
  },
  {
    start: "2023-09-09 20:30",
    name: "Toxic Girls",
    description: "Playing songs by Katy Perry, Dua Lipa, Lizzo, McFly and more",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "toxic_girls.jpg",
    image_position: "top",
  },
  {
    start: "2023-09-16 20:30",
    name: "No Middle Ground",
    description: "The Smiths, Human League, The Sex Pistols and more",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "nmg1.jpg",
    image_position: "top",
  },
  {
    start: "2023-10-14 21:00",
    name: "Jordan Marvell",
    description:
      "Performing songs by Bob Marley, Bruno Mars, Ed Sheeran and more",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "jordan.jpg",
    image_position: "top",
  },
  {
    start: "2023-10-27 20:30",
    name: "Pre Fab 4 Beatles",
    description: "Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "pre-fab-4.jpg",
    image_position: "top",
  },
  {
    start: "2023-11-04 20:30",
    name: "Splat The Rat",
    description: "Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "splat.jpeg",
    image_position: "top",
  },
  {
    start: "2023-11-25 21:00",
    name: "Darren Hunt",
    description: "Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "dh.jpeg",
    image_position: "top",
  },
  {
    start: "2023-11-18 20:30",
    name: "Lonely Road Band",
    description: "Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "lrb.png",
    image_position: "top",
  },
  {
    start: "2023-11-11 20:30",
    name: "The Replays",
    description: "Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "replays-poster.jpg",
    image_position: "top",
  },
  {
    start: "2023-11-11 19:30",
    name: "Charity Quiz",
    description: "TICKETS ONLY - £10 per person, order in advance",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "charityquiz.jpeg",
    image_position: "top",
  },
  {
    start: "2023-11-28 19:30",
    name: "Bernie Scott - Mediumship",
    description: "CANCELLED - Refunds available at the bar",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "CANCELLED.png",
    image_position: "top",
  },
  {
    start: "2023-12-16 13:30",
    name: "Children's Christmas Party",
    description: "TICKETS ONLY - £5 per child, order in advance",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "kids-party.png",
    image_position: "top",
  },

  {
    start: "2023-12-09 20:30",
    name: "Steven Tilley",
    description: "Covering genres from across the decades",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "stevet.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-02 20:30",
    name: "Over The Hill",
    description: "Playing live",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "oth.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-10 16:00",
    name: "Tildy",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "tildy.png",
    image_position: "top",
  },
  {
    start: "2023-12-16 20:30",
    name: "Locarno Beat",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "l-beat.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-17 18:00",
    name: "Christmas Raffle",
    description: "Competition",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "christmas_dinner.jpeg",
    image_position: "middle",
  },
  {
    start: "2023-12-23 20:30",
    name: "Get Carter",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "get-carter-band.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-25 11:00",
    name: "Christmas Opening",
    description: "Bar open 11am-1pm - drinking up by 2pm",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "christmas.jpeg",
    image_position: "middle",
  },
  {
    start: "2023-12-26 20:30",
    name: "Toxic Girls",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "toxic-girls.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-29 20:30",
    name: "Locomotion",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "locomotion.jpg",
    image_position: "top",
  },
  {
    start: "2023-12-31 20:30",
    name: "DJ New Years Eve party",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coming-soon.png",
    image_position: "top",
  },
  {
    start: "2023-12-15 20:00",
    name: "Rock n Roll Bingo",
    description: "100% paid out in winnings!",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rocknroll-bingo.jpeg",
    image_position: "top",
  },
  {
    start: "2023-12-17 16:00",
    name: "Jol Rose",
    description: "Swindon-based singer-songwriter",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "jol.png",
    image_position: "top",
  },
  {
    start: "2024-01-20 20:00",
    name: "Music Quiz",
    description: "Teams of up to 6. £5 per team",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "music_quiz2.png",
    image_position: "top",
  },
  {
    start: "2024-01-27 20:30",
    name: "The Social Fretwork",
    description: "An evening of great songs from across the decades",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "social_fret.png",
    image_position: "top",
  },
  {
    start: "2024-01-13 21:00",
    name: "The Vipers",
    description: "Real, Raw, Rockabilly",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "vipers.png",
    image_position: "top",
  },
  {
    start: "2024-02-24 20:30",
    name: "Sister Sister",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sistersister.png",
    image_position: "top",
  },
  {
    start: "2024-02-10 20:30",
    name: "Splat The Rat",
    description: "It's a Folk Revolution",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "splat_poster.jpg",
    image_position: "top",
  },
  {
    start: "2024-02-03 20:30",
    name: "The Corsairs",
    description: "Playing songs from The Jam, Madness and many more",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "corsairs.jpg",
    image_position: "top",
  },
  {
    start: "2024-02-14 19:00",
    name: "It's a Date",
    description: "Please purchase tickets at the bar",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "valentines.png",
    image_position: "top",
  },
  {
    start: "2024-02-17 20:30",
    name: "Haney's Big House",
    description: "Live Entertaiment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "haneys.png",
    image_position: "top",
  },
  {
    start: "2024-03-02 20:30",
    name: "Deltonians",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "deltonians.png",
    image_position: "top",
  },
  {
    start: "2024-03-09 20:00",
    name: "RocknRoll Bingo",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rocknroll.png",
    image_position: "top",
  },
  {
    start: "2024-03-16 21:00",
    name: "Get Carter",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "get-carter-generic.jpg",
    image_position: "top",
  },
  {
    start: "2024-03-23 20:30",
    name: "Rude Mood",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "coming-soon.png",
    image_position: "top",
  },
  {
    start: "2024-03-30 20:30",
    name: "Chaos Brothers",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "chaosbs.jpg",
    image_position: "top",
  },
  {
    start: "2024-03-17 17:30",
    name: "Jol Rose",
    description: "St Patricks day Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "jol-rose-g.jpg",
    image_position: "top",
  },
  {
    start: "2024-03-31 16:00",
    name: "Horse Racing",
    description: "All funds raised will be donated to 'Different Strokes'",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "horses.jpg",
    image_position: "top",
  },
  {
    start: "2024-03-31 19:00",
    name: "Shane Sheehan",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "shane2.png",
    image_position: "top",
  },
  {
    start: "2024-04-01 13:00",
    name: "Open from 1pm",
    description: "Draughts @ £2.50",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "beer.png",
    image_position: "top",
  },
  {
    start: "2024-04-06 20:30",
    name: "Sophia & Motown Band",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sophia.jpg",
    image_position: "top",
  },
  {
    start: "2024-04-13 20:30",
    name: "Over the Hill",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "over-the-hill.png",
    image_position: "top",
  },
  {
    start: "2024-04-20 20:00",
    name: "Music Quiz",
    description: "Kate & Mac",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "music-quiz.png",
    image_position: "top",
  },
  {
    start: "2024-04-27 20:30",
    name: "One Chord Wonders",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "onechord.jpg",
    image_position: "top",
  },

  {
    start: "2024-05-04 20:30",
    name: "The Vipers",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "vipers.png",
    image_position: "middle",
  },
  {
    start: "2024-05-05 19:00",
    name: "Toxic Girls",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "toxic_girls.jpg",
    image_position: "top",
  },
  {
    start: "2024-05-06 13:00",
    name: "Draughts & house drinks £2.50",
    description: "Open from 1pm, fresh rolls available",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "beer.png",
    image_position: "top",
  },
  {
    start: "2024-05-11 20:00",
    name: "Rock n Roll Bingo",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rocknroll-bingo.jpeg",
    image_position: "top",
  },
  {
    start: "2024-05-18 20:00",
    name: "41 Fords",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "41fords.jpg",
    image_position: "top",
  },
  {
    start: "2024-05-25 21:00",
    name: "Steven Tilley",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "steven-tilley.jpg",
    image_position: "top",
  },
  {
    start: "2024-05-26 15:30",
    name: "Tildy",
    description: "CANCELLED",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "tildycancelled.jpg",
    image_position: "top",
  },
  {
    start: "2024-05-26 19:30",
    name: "Locarno Beat",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "l-beat.jpg",
    image_position: "top",
  },
  {
    start: "2024-05-27 13:00",
    name: "Draughts & house drinks £2.50",
    description: "Open from 1pm, fresh rolls available",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "beer.png",
    image_position: "top",
  },

  {
    start: "2024-06-01 20:30",
    name: "Abstract",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "abstract.jpeg",
    image_position: "top",
  },
  {
    start: "2024-06-08 20:30",
    name: "Sister Sister",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "ss24.jpeg",
    image_position: "top",
  },
  {
    start: "2024-06-15 20:00",
    name: "Music Quiz with Kate & Mac",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "quiz.png",
    image_position: "top",
  },
  {
    start: "2024-06-22 20:30",
    name: "Mean as Custard",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "mac.jpg",
    image_position: "top",
  },
  {
    start: "2024-07-06 20:30",
    name: "NMG (No Middle Ground)",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "nmg24.jpg",
    image_position: "top",
  },
  {
    start: "2024-07-13 21:00",
    name: "Darren Hunt",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "darrenjuly24.jpeg",
    image_position: "top",
  },
  {
    start: "2024-07-19 21:00",
    name: "Pre Fab Four",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "prefab4.jpeg",
    image_position: "middle",
  },
  {
    start: "2024-07-27 20:30",
    name: "Sonic Alert",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sonic_alert.jpeg",
    image_position: "top",
  },
  {
    start: "2024-06-16 15:00",
    name: "Fathers Day Charity Event",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "fathers-day.png",
    image_position: "top",
  },
  {
    start: "2024-08-03 20:30",
    name: "247",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "groove247.jpeg",
    image_position: "top",
  },
  {
    start: "2024-08-17 21:00",
    name: "Last Train Smokin'",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "lasttrain.png",
    image_position: "top",
  },
  {
    start: "2024-08-25 18:30",
    name: "Drew Bryant",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "drew-bryant-25-aug.jpeg",
    image_position: "top",
  },
  {
    start: "2024-08-24 21:00",
    name: "Get Carter",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "getcarteraug.jpg",
    image_position: "top",
  },
  {
    start: "2024-09-07 21:00",
    name: "Rubarb & Custard",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rubharb.jpg",
    image_position: "top",
  },
  {
    start: "2024-09-14 21:00",
    name: "One in the Chamber",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "chamber24aug.jpeg",
    image_position: "top",
  },
  {
    start: "2024-09-21 21:00",
    name: "The Vipers",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "VipersNEW.png",
    image_position: "top",
  },
  {
    start: "2024-09-28 21:00",
    name: "Rude Mood",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rudemoodsep24.jpeg",
    image_position: "top",
  },
  {
    start: "2024-10-05 21:00",
    name: "Locomotion",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "locomotionsep24.jpeg",
    image_position: "top",
  },
  {
    start: "2024-10-26 20:00",
    name: "DJ Liam",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "dj_liam.jpg",
    image_position: "top",
  },
  {
    start: "2024-10-19 20:00",
    name: "RockNRoll Bingo",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "rocknroll_bingo_24.png",
    image_position: "top",
  },
  {
    start: "2024-11-09 21:00",
    name: "The Corsairs",
    description: "Live entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "corsairs_oct_24.jpeg",
    image_position: "top",
  },
  {
    start: "2024-12-21 13:00",
    name: "Children's Christmas Party",
    description: "Register at bar",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "xmas_party.jpg",
    image_position: "top",
  },
  {
    start: "2024-11-02 21:00",
    name: "Over The Hill",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "overthehill.jpeg",
    image_position: "top",
  },
  {
    start: "2024-11-10 20:00",
    name: "Quiz night with Paul",
    description: "All money paid out in prizes",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "quiz-time.png",
    image_position: "top",
  },
  {
    start: "2024-11-16 21:00",
    name: "Borrowed & Blue",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "borrowed.jpeg",
    image_position: "top",
  },
  {
    start: "2024-11-23 21:00",
    name: "Darren Hunt",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "darren_hunt.png",
    image_position: "top",
  },
  {
    start: "2024-11-24 18:30",
    name: "Tildy",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "tildy24.png",
    image_position: "top",
  },
  {
    start: "2024-11-30 21:00",
    name: "Pre Fab Four ",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "pre-fab-4.png",
    image_position: "top",
  },
  {
    start: "2024-12-07 21:00",
    name: "JB & The Mojo Makers DUO",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "jb.jpg",
    image_position: "top",
  },
  {
    start: "2024-12-14 21:00",
    name: "Sister Sister",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "sister-sister.jpg",
    image_position: "top",
  },
  {
    start: "2024-12-21 21:00",
    name: "One in the Chamber",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "one-in-the-chamber.png",
    image_position: "top",
  },
  {
    start: "2024-12-22 18:30",
    name: "Toxic Girls",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "toxic_girls.jpg",
    image_position: "top",
  },
  {
    start: "2024-12-24 16:00",
    name: "Xmas Eve - Jol Rose",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "jol.png",
    image_position: "top",
  },
  {
    start: "2024-12-27 21:00",
    name: "The Vipers",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "vipers.png",
    image_position: "top",
  },
  {
    start: "2024-12-28 21:00",
    name: "Locarno Beat",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "locarno_beat.jpg",
    image_position: "top",
  },
  {
    start: "2024-12-29 16:30",
    name: "Drew Bryant",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "drew-bryant.png",
    image_position: "top",
  },
  {
    start: "2024-12-31 21:00",
    name: "New Years Eve Party",
    description: "Live Entertainment",
    fb_link: "https://www.facebook.com/ashrd1718",
    image: "nye24-25.jpg",
    image_position: "top",
  },
];
